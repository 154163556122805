//  Breakpoints
// default below 576px (Portrait Phones)
$breakpoint-sm: 576px; // Landscape Phones
$breakpoint-md: 768px; // Tablets
$breakpoint-lg: 992px; // Desktops
$breakpoint-xl: 1200px; // Large Desktops
$breakpoint-xxl: 1600px; // Extra Large Desktops
$breakpoint-vr: 600px; // Standalone VR Browsers

// Font styles
$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-2xl: 28px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Border styles
$border-radius-regular: 8px;
$border-radius-small: 6px;

// Spacing
$spacing-2xs: 4px;
$spacing-xs: 8px;
$spacing-sm: 12px;
$spacing-md: 16px;
$spacing-lg: 20px;
$spacing-xl: 24px;

// Utility colors
$transparent: transparent;
$transparent-hover: rgba(0, 0, 0, 0.08);
$transparent-pressed: rgba(0, 0, 0, 0.12);

$white: #ffffff;
$white-hover: #e7e7e7;
$white-pressed: #dbdbdb;

$lightgrey: #e7e7e7;
$lightgrey-hover: #f5f5f5;
$lightgrey-pressed: #dbdbdb;

$grey: #bbbbbb;
$grey-hover: #c7c7c7;
$grey-pressed: #adadad;

$darkgrey: #868686;
$darkgrey-hover: #949494;
$darkgrey-pressed: #7a7a7a;

$black: #000000;
$black-hover: #404040;
$black-pressed: #7a7a7a;

$red: #f5325c;
$red-hover: #f64b70;
$red-pressed: #f41a49;

$orange: #ff8500;
$orange-hover: #ff911a;
$orange-pressed: #e67800;

$green: #7ed320;
$green-hover: #8cdf2f;
$green-pressed: #72be1d;

$blue: #C3A254;
$blue-hover: #c3a254;
$blue-pressed: #c3a254;

$purple: #7854f6;
$purple-hover: #8c6ef7;
$purple-pressed: #663df5;

$recessed-bg: #f9f9f9;

$yellow: #ffc000;

// Brand Colors
$spoke-primary-color: #2f80ed;
$twitter-primary-color: #6fc0fd;
$slack-primary-color: #611f69;
$discord--primary-color: #7289da;

// Discord Bot Page Colors
$discord-bg-color: #2a2d32;
$discord-text1-color: white;
$discord-text2-color: #a3a3a3;
$discord-text3-color: rgb(127, 127, 127);
$discord-text4-color: rgb(64, 64, 64);

// Theme SCSS Variables:
// Reference these variables in the Hubs codebase.
// The CSS variables they reference are defined in ./global.scss such that they are only included on the page once.
$font-family-default: var(--font-family-default);
$font-family-monospace: var(--font-family-monospace);

$text1-color: var(--text1-color);
$text1-color-hover: var(--text1-color-hover);
$text1-color-pressed: var(--text1-color-pressed);
$text2-color: var(--text2-color);
$text2-color-hover: var(--text2-color-hover);
$text2-color-pressed: var(--text2-color-pressed);
$text3-color: var(--text3-color);
$text3-color-hover: var(--text3-color-hover);
$text3-color-pressed: var(--text3-color-pressed);
$text4-color: var(--text4-color);
$text4-color-hover: var(--text4-color-hover);
$text4-color-pressed: var(--text4-color-pressed);
$text5-color: var(--text5-color);
$text5-color-hover: var(--text5-color-hover);
$text5-color-pressed: var(--text5-color-pressed);
$text-inverted-color: var(--text-inverted-color);

$link-color: var(--link-color);
$link-color-hover: var(--link-color-hover);
$link-color-pressed: var(--link-color-pressed);

$accept-color: var(--accept-color);
$accept-border-color: var(--accept-border-color);
$accept-color-hover: var(--accept-color-hover);
$accept-color-pressed: var(--accept-color-pressed);

$cancel-color: var(--cancel-color);
$cancel-color-hover: var(--cancel-color-hover);
$cancel-color-pressed: var(--cancel-color-pressed);

$accent1-color: var(--accent1-color);
$accent1-border-color: var(--accent1-border-color);
$accent1-color-hover: var(--accent1-color-hover);
$accent1-color-pressed: var(--accent1-color-pressed);

$accent2-color: var(--accent2-color);
$accent2-border-color: var(--accent2-border-color);
$accent2-color-hover: var(--accent2-color-hover);
$accent2-color-pressed: var(--accent2-color-pressed);

$accent3-color: var(--accent3-color);
$accent3-color-hover: var(--accent3-color-hover);
$accent3-color-pressed: var(--accent3-color-pressed);
$accent3-border-color: var(--accent3-border-color);

$accent4-color: var(--accent4-color);
$accent4-border-color: var(--accent4-border-color);
$accent4-color-hover: var(--accent4-color-hover);
$accent4-color-pressed: var(--accent4-color-pressed);

$accent5-color: var(--accent5-color);
$accent5-border-color: var(--accent5-border-color);
$accent5-color-hover: var(--accent5-color-hover);
$accent5-color-pressed: var(--accent5-color-pressed);

$accent6-color: var(--accent6-color);
$accent6-color-hover: var(--accent6-color-hover);
$accent6-color-pressed: var(--accent6-color-pressed);

$primary-color: var(--primary-color);
$primary-color-hover: var(--primary-color-hover);
$primary-color-pressed: var(--primary-color-pressed);

$secondary-color: var(--secondary-color);

$background1-color: var(--background1-color);
$background2-color: var(--background2-color);
$background3-color: var(--background3-color);
$background4-color: var(--background4-color);

$loading-screen-background: var(--loading-screen-background);

$border1-color: var(--border1-color);
$border2-color: var(--border2-color);

$outline-color: var(--outline-color);

$shadow-color: var(--shadow-color);

$basic-color: var(--basic-color);
$basic-color-hover: var(--basic-color-hover);
$basic-color-pressed: var(--basic-color-pressed);
$basic-border-color: var(--basic-border-color);

$disabled-text-color: var(--disabled-text-color);
$disabled-bg-color: var(--disabled-bg-color);
$disabled-icon-color: var(--disabled-icon-color);

$radio-border-color: var(--radio-border-color);
$radio-bg-color: var(--radio-bg-color);
$radio-bg-color-hover: var(--radio-bg-color-hover);
$radio-bg-color-pressed: var(--radio-bg-color-pressed);

$toggle-button-color: var(--toggle-button-color);

$input-bg-color: var(--input-bg-color);
$input-icon-color: var(--input-icon-color);
$input-border-color: var(--input-border-color);
$input-border-color-hover: var(--input-border-color-hover);
$input-outline-color: var(--input-outline-color);

$button-text-color: var(--button-text-color);
$button-border-color: var(--button-border-color);
$button-bg-color: var(--button-bg-color);
$button-bg-color-hover: var(--button-bg-color-hover);
$button-bg-color-pressed: var(--button-bg-color-pressed);

$active-text-color: var(--active-text-color);
$active-color: var(--active-color);
$active-color-hover: var(--active-color-hover);
$active-color-pressed: var(--active-color-pressed);

$chat-bubble-bg-color-sent: var(--chat-bubble-bg-color-sent);
$chat-bubble-text-color-sent: var(--chat-bubble-text-color-sent);
$chat-bubble-link-color-sent-hover: var(--chat-bubble-link-color-sent-hover);
$chat-bubble-link-color-sent-pressed: var(--chat-bubble-link-color-sent-pressed);
$chat-bubble-bg-color-received: var(--chat-bubble-bg-color-received);

$favorite-color: var(--favorite-color);

$error-color: var(--error-color);

$overlay-bg-color: var(--overlay-bg-color);
$overlay-text-color: var(--overlay-text-color);
$overlay-border-color: var(--overlay-border-color);

$toolbar-icon-color: var(--toolbar-icon-color);
$toolbar-icon-selected-bg: var(--toolbar-icon-selected-bg);
$toolbar-basic-icon-color: var(--toolbar-basic-icon-color);
$toolbar-basic-selected-icon-color: var(--toolbar-basic-selected-icon-color);
$toolbar-basic-color: var(--toolbar-basic-color);
$toolbar-basic-color-hover: var(--toolbar-basic-color-hover);
$toolbar-basic-color-pressed: var(--toolbar-basic-color-pressed);
$toolbar-basic-border-color: var(--toolbar-basic-border-color);

$tile-text-color: var(--tile-text-color);
$tile-bg-color: var(--tile-bg-color);
$tile-bg-color-hover: var(--tile-bg-color-hover);
$tile-bg-color-pressed: var(--tile-bg-color-pressed);
$tile-button-text-color: var(--tile-button-text-color);
$tile-button-bg-color: var(--tile-button-bg-color);
$tile-button-bg-color-hover: var(--tile-button-bg-color-hover);
$tile-button-bg-color-pressed: var(--tile-button-bg-color-pressed);
$tile-button-border-color: var(--tile-button-border-color);

// Mozilla Lilypad compatible variables

/**
  PRIMARY INTERACTION
  **/
$color-interaction-primary: var(--color-interaction-primary);
$color-interaction-primary-hover: var(--color-interaction-primary-hover);
$color-interaction-primary-active: var(--color-interaction-primary-active);
$color-interaction-primary-disabled: var(--color-interaction-primary-disabled);
$color-interaction-primary-alt: var(--color-interaction-primary-alt);
$color-interaction-primary-alt-hover: var(--color-interaction-primary-alt-hover);
$color-interaction-primary-alt-active: var(--color-interaction-primary-alt-active);
$color-interaction-primary-alt-disabled: var(--color-interaction-primary-alt-disabled);

/**
  SECONDARY INTERACTION
  **/
$color-interaction-secondary: var(--color-interaction-secondary);
$color-interaction-secondary-hover: var(--color-interaction-secondary-hover);
$color-interaction-secondary-active: var(--color-interaction-secondary-active);
$color-interaction-secondary-disabled: var(--color-interaction-secondary-disabled);
$color-interaction-secondary-alt: var(--color-interaction-secondary-alt);
$color-interaction-secondary-alt-hover: var(--color-interaction-secondary-alt-hover);
$color-interaction-secondary-alt-active: var(--color-interaction-secondary-alt-active);
$color-interaction-secondary-alt-disabled: var(--color-interaction-secondary-alt-disabled);

/**
  SEMANTIC
  **/
$color-semantic-info: var(--color-semantic-info);
$color-semantic-info-hover: var(--color-semantic-info-hover);
$color-semantic-info-active: var(--color-semantic-info-active);
$color-semantic-disabled: var(--color-semantic-disabled);
$color-semantic-success: var(--color-semantic-success);
$color-semantic-success-hover: var(--color-semantic-success-hover);
$color-semantic--success-active: var(--color-semantic-success-active);
$color-semantic-success-disabled: var(--color-semantic-success-disabled);
$color-semantic-warning: var(--color-semantic-warning);
$color-semantic-warning-hover: var(--color-semantic-warning-hover);
$color-semantic-warning-active: var(--color-semantic-warning-active);
$color-semantic-warning-disabled: var(--color-semantic-warning-disabled);
$color-semantic-critical: var(--color-semantic-critical);
$color-semantic-critical-hover: var(--color-semantic-critical-hover);
$color-semantic-critical-active: var(--color-semantic-critical-active);
$color-semantic-critical-disabled: var(--color-semantic-critical-disabled);
$color-semantic-critical-bg-alt: var(--color-semantic-critical-bg-alt);
$color-semantic-neutral: var(--color-semantic-neutral);
$color-semantic-neutral-hover: var(--color-semantic-neutral-hover);
$color-semantic-neutral-active: var(--color-semantic-neutral-active);
$color-semantic-neutral-inactive: var(--color-semantic-neutral-inactive);

/**
  TEXT
  **/
$color-text-main: var(--color-text-main);
$color-text-subtle: var(--color-text-subtle);
$color-text-reverse: var(--color-text-reverse);
$color-text-reverse-subtle: var(--color-text-reverse-subtle);
$color-text-disabled: var(--color-text-disabled);
$color-text-info: var(--color-text-info);
$color-text-success: var(--color-text-success);
$color-text-warning: var(--color-text-warning);
$color-text-critical: var(--color-text-critical);

/**
  BORDER
  **/
$color-border-1: var(--color-border-1);
$color-border-2: var(--color-border-2);
$color-border-3: var(--color-border-3);

/**
  NEUTRALS
  **/
$color-neutral-0: var(--color-neutral-0);
$color-neutral-0-reverse: var(--color-neutral-0-reverse);
$color-neutral-1: var(--color-neutral-1);
$color-neutral-2: var(--color-neutral-2);
$color-neutral-3: var(--color-neutral-3);

/**
  STATUS
  **/
$color-status-ready: var(--color-status-ready);
$color-status-offline: var(--color-status-offline);
$color-status-busy: var(--color-status-busy);

/**
  BACKGROUNDS
  **/
$color-background-overlay: var(--color-background-overlay);
$color-background-callout: var(--color-background-subtle-callout);
$color-background-modal-overlay: var(--color-background-modal-overlay);
$color-background-critical: var(--color-background-critical);
$color-background-neutral-0: var(--color-background-neutral-0);

/**
  MENU
  **/
$color-interactions-menu: var(--color-interactions-menu);
$color-interactions-menu-hover: var(--color-interactions-menu-hover);
$color-interactions-menu-inactive: var(--color-interactions-menu-inactive);
